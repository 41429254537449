<template>
  <h4>Administrasi Kesiswaan</h4>
  <div class="card shadow vl-parent" ref="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills">
        <li class="nav-item">
          <router-link to="/kesiswaan/data-siswa" class="nav-link" active-class="active">Siswa</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/impor-ppdb" class="nav-link" active-class="active">Impor PPDB</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/data-agama" class="nav-link" active-class="active">Agama</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/status-siswa" class="nav-link" active-class="active">Status Siswa</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/tempat-tinggal" class="nav-link" active-class="active">Tempat
            Tinggal</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/moda-transportasi" class="nav-link" active-class="active">Moda
            Transportasi</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/data-hobi" class="nav-link" active-class="active">Hobi</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/cita-cita" class="nav-link" active-class="active">Cita-cita</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/data-pendidikan" class="nav-link" active-class="active">Pendidikan</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/data-pekerjaan" class="nav-link" active-class="active">Pekerjaan</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/data-penghasilan" class="nav-link" active-class="active">Penghasilan</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/kesiswaan/status-ortu" class="nav-link" active-class="active">Status Orang Tua</router-link>
        </li>
      </ul>
    </div>
    <div v-if="!$route.params.data">
      <div class="card-body">
        <h5>Data Bagian Kesiswaan</h5>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 g-3">
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Siswa</span>
                <router-link to="/kesiswaan/data-siswa" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.siswaAktif">
                    {{ dataKesiswaan.siswaAktif || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Impor PPDB</span>
                <router-link to="/kesiswaan/impor-ppdb" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.imporPpdb">
                    {{ dataKesiswaan.imporPpdb || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Agama</span>
                <router-link to="/kesiswaan/data-agama" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.agama">
                    {{ dataKesiswaan.agama || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Status Siswa</span>
                <router-link to="/kesiswaan/status-siswa" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.statusSiswa">
                    {{ dataKesiswaan.statusSiswa || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Tempat Tinggal</span>
                <router-link to="/kesiswaan/tempat-tinggal" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.tempatTinggal">
                    {{ dataKesiswaan.tempatTinggal || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Moda Transportasi</span>
                <router-link to="/kesiswaan/moda-transportasi" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.modaTransportasi">
                    {{ dataKesiswaan.modaTransportasi || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Hobi</span>
                <router-link to="/kesiswaan/data-hobi" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.hobi">
                    {{ dataKesiswaan.hobi || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Cita-cita</span>
                <router-link to="/kesiswaan/cita-cita" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.citaCita">
                    {{ dataKesiswaan.citaCita || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Pendidikan</span>
                <router-link to="/kesiswaan/data-pendidikan" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.pendidikan">
                    {{ dataKesiswaan.pendidikan || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Pekerjaan</span>
                <router-link to="/kesiswaan/data-pekerjaan" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.pekerjaan">
                    {{ dataKesiswaan.pekerjaan || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Penghasilan</span>
                <router-link to="/kesiswaan/data-penghasilan" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.penghasilan">
                    {{ dataKesiswaan.penghasilan || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Status Orang Tua</span>
                <router-link to="/kesiswaan/status-ortu" class="ms-auto d-inline-flex align-items-center"
                  v-tooltip="'Kelola Data'">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <transition name="slide-up" mode="out-in">
                  <span class="h1 d-inline-block my-0" :key="dataKesiswaan.statusOrtu">
                    {{ dataKesiswaan.statusOrtu || 0 }}
                  </span>
                </transition>
                <span class="ms-2 text-body-secondary fs-6">data</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <data-siswa v-else-if="$route.params.data === 'data-siswa'" />
    <impor-ppdb v-else-if="$route.params.data === 'impor-ppdb'" />
    <data-agama v-else-if="$route.params.data === 'data-agama'" />
    <status-siswa v-else-if="$route.params.data === 'status-siswa'" />
    <tempat-tinggal v-else-if="$route.params.data === 'tempat-tinggal'" />
    <moda-transportasi v-else-if="$route.params.data === 'moda-transportasi'" />
    <data-hobi v-else-if="$route.params.data === 'data-hobi'" />
    <cita-cita v-else-if="$route.params.data === 'cita-cita'" />
    <data-pendidikan v-else-if="$route.params.data === 'data-pendidikan'" />
    <data-pekerjaan v-else-if="$route.params.data === 'data-pekerjaan'" />
    <data-penghasilan v-else-if="$route.params.data === 'data-penghasilan'" />
    <status-ortu v-else-if="$route.params.data === 'status-ortu'" />
    <not-found v-else />
  </div>
</template>

<script>
import WebSocketService from '@/components/WebSocketService';
import DataSiswa from '@/components/DataSiswa';
import ImporPpdb from '@/components/ImporPpdb';
import DataAgama from '@/components/DataAgama';
import StatusSiswa from '@/components/StatusSiswa';
import TempatTinggal from '@/components/TempatTinggal';
import ModaTransportasi from '@/components/ModaTransportasi';
import DataHobi from '@/components/DataHobi';
import CitaCita from '@/components/CitaCita';
import DataPendidikan from '@/components/DataPendidikan';
import DataPekerjaan from '@/components/DataPekerjaan';
import DataPenghasilan from '@/components/DataPenghasilan';
import StatusOrtu from '@/components/StatusOrtu';
import NotFound from '@/components/NotFound';
export default {
  name: 'KesiswaanView',
  components: {
    DataSiswa,
    ImporPpdb,
    DataAgama,
    StatusSiswa,
    TempatTinggal,
    ModaTransportasi,
    DataHobi,
    CitaCita,
    DataPendidikan,
    DataPekerjaan,
    DataPenghasilan,
    StatusOrtu,
    NotFound,
  },
  data() {
    return {
      dataListener: () => {
        this.getDataKesiswaan();
      },
      dataKesiswaan: {
        siswaAktif: 0,
        imporPpdb: 0,
        agama: 0,
        statusSiswa: 0,
        tempatTinggal: 0,
        modaTransportasi: 0,
        hobi: 0,
        citaCita: 0,
        pendidikan: 0,
        pekerjaan: 0,
        penghasilan: 0,
        statusOrtu: 0,
      }
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    user() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    let memuat = this.$loading.show({
      container: this.$refs.card,
      loader: 'spinner'
    });
    this.getDataKesiswaan();
    setTimeout(() => {
      memuat.hide();
    }, 250);
    WebSocketService.on("inserted-siswa", this.dataListener);
    WebSocketService.on("updated-siswa", this.dataListener);
    WebSocketService.on("deleted-siswa", this.dataListener);
    WebSocketService.on("imported-ppdb", this.dataListener);
    WebSocketService.on("graduated-anggota-rombel", this.dataListener);
    WebSocketService.on("inserted-agama", this.dataListener);
    WebSocketService.on("updated-agama", this.dataListener);
    WebSocketService.on("deleted-agama", this.dataListener);
    WebSocketService.on("inserted-status-siswa", this.dataListener);
    WebSocketService.on("updated-status-siswa", this.dataListener);
    WebSocketService.on("deleted-status-siswa", this.dataListener);
    WebSocketService.on("inserted-tempat-tinggal", this.dataListener);
    WebSocketService.on("updated-tempat-tinggal", this.dataListener);
    WebSocketService.on("deleted-tempat-tinggal", this.dataListener);
    WebSocketService.on("inserted-moda-transportasi", this.dataListener);
    WebSocketService.on("updated-moda-transportasi", this.dataListener);
    WebSocketService.on("deleted-moda-transportasi", this.dataListener);
    WebSocketService.on("inserted-hobi", this.dataListener);
    WebSocketService.on("updated-hobi", this.dataListener);
    WebSocketService.on("deleted-hobi", this.dataListener);
    WebSocketService.on("inserted-cita-cita", this.dataListener);
    WebSocketService.on("updated-cita-cita", this.dataListener);
    WebSocketService.on("deleted-cita-cita", this.dataListener);
    WebSocketService.on("inserted-pendidikan", this.dataListener);
    WebSocketService.on("updated-pendidikan", this.dataListener);
    WebSocketService.on("deleted-pendidikan", this.dataListener);
    WebSocketService.on("inserted-pekerjaan", this.dataListener);
    WebSocketService.on("updated-pekerjaan", this.dataListener);
    WebSocketService.on("deleted-penghasilan", this.dataListener);
    WebSocketService.on("inserted-penghasilan", this.dataListener);
    WebSocketService.on("updated-penghasilan", this.dataListener);
    WebSocketService.on("deleted-penghasilan", this.dataListener);
    WebSocketService.on("inserted-status-ortu", this.dataListener);
    WebSocketService.on("updated-status-ortu", this.dataListener);
    WebSocketService.on("deleted-status-ortu", this.dataListener);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-siswa", this.dataListener);
    WebSocketService.off("updated-siswa", this.dataListener);
    WebSocketService.off("deleted-siswa", this.dataListener);
    WebSocketService.off("imported-ppdb", this.dataListener);
    WebSocketService.off("graduated-anggota-rombel", this.dataListener);
    WebSocketService.off("inserted-agama", this.dataListener);
    WebSocketService.off("updated-agama", this.dataListener);
    WebSocketService.off("deleted-agama", this.dataListener);
    WebSocketService.off("inserted-status-siswa", this.dataListener);
    WebSocketService.off("updated-status-siswa", this.dataListener);
    WebSocketService.off("deleted-status-siswa", this.dataListener);
    WebSocketService.off("inserted-tempat-tinggal", this.dataListener);
    WebSocketService.off("updated-tempat-tinggal", this.dataListener);
    WebSocketService.off("deleted-tempat-tinggal", this.dataListener);
    WebSocketService.off("inserted-moda-transportasi", this.dataListener);
    WebSocketService.off("updated-moda-transportasi", this.dataListener);
    WebSocketService.off("deleted-moda-transportasi", this.dataListener);
    WebSocketService.off("inserted-hobi", this.dataListener);
    WebSocketService.off("updated-hobi", this.dataListener);
    WebSocketService.off("deleted-hobi", this.dataListener);
    WebSocketService.off("inserted-cita-cita", this.dataListener);
    WebSocketService.off("updated-cita-cita", this.dataListener);
    WebSocketService.off("deleted-cita-cita", this.dataListener);
    WebSocketService.off("inserted-pendidikan", this.dataListener);
    WebSocketService.off("updated-pendidikan", this.dataListener);
    WebSocketService.off("deleted-pendidikan", this.dataListener);
    WebSocketService.off("inserted-pekerjaan", this.dataListener);
    WebSocketService.off("updated-pekerjaan", this.dataListener);
    WebSocketService.off("deleted-penghasilan", this.dataListener);
    WebSocketService.off("inserted-penghasilan", this.dataListener);
    WebSocketService.off("updated-penghasilan", this.dataListener);
    WebSocketService.off("deleted-penghasilan", this.dataListener);
    WebSocketService.off("inserted-status-ortu", this.dataListener);
    WebSocketService.off("updated-status-ortu", this.dataListener);
    WebSocketService.off("deleted-status-ortu", this.dataListener);
  },
  methods: {
    async getDataKesiswaan() {
      try {
        const response = await this.axios.post('kesiswaan/data/all', {}, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          const data = response.data;
          this.dataKesiswaan.siswaAktif = this.dataKesiswaan.siswaAktif == data.siswaAktif ? this.dataKesiswaan.siswaAktif : data.siswaAktif;
          this.dataKesiswaan.imporPpdb = this.dataKesiswaan.imporPpdb == data.imporPpdb ? this.dataKesiswaan.imporPpdb : data.imporPpdb;
          this.dataKesiswaan.agama = this.dataKesiswaan.agama == data.agama ? this.dataKesiswaan.agama : data.agama;
          this.dataKesiswaan.statusSiswa = this.dataKesiswaan.statusSiswa == data.statusSiswa ? this.dataKesiswaan.statusSiswa : data.statusSiswa;
          this.dataKesiswaan.tempatTinggal = this.dataKesiswaan.tempatTinggal == data.tempatTinggal ? this.dataKesiswaan.tempatTinggal : data.tempatTinggal;
          this.dataKesiswaan.modaTransportasi = this.dataKesiswaan.modaTransportasi == data.modaTransportasi ? this.dataKesiswaan.modaTransportasi : data.modaTransportasi;
          this.dataKesiswaan.hobi = this.dataKesiswaan.hobi == data.hobi ? this.dataKesiswaan.hobi : data.hobi;
          this.dataKesiswaan.citaCita = this.dataKesiswaan.citaCita == data.citaCita ? this.dataKesiswaan.citaCita : data.citaCita;
          this.dataKesiswaan.pendidikan = this.dataKesiswaan.pendidikan == data.pendidikan ? this.dataKesiswaan.pendidikan : data.pendidikan;
          this.dataKesiswaan.pekerjaan = this.dataKesiswaan.pekerjaan == data.pekerjaan ? this.dataKesiswaan.pekerjaan : data.pekerjaan;
          this.dataKesiswaan.penghasilan = this.dataKesiswaan.penghasilan == data.penghasilan ? this.dataKesiswaan.penghasilan : data.penghasilan;
          this.dataKesiswaan.statusOrtu = this.dataKesiswaan.statusOrtu == data.statusOrtu ? this.dataKesiswaan.statusOrtu : data.statusOrtu;
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style></style>