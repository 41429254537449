<template>
  <div class="text-center">
    <img src="../assets/logo.png" class="mb-4" width="150">
    <p>Selamat Datang di Aplikasi Admin Sistem Informasi Manajemen SMK PGRI 1 KEDIRI.</p>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
